import { Modal, Button, Space } from '@arco-design/web-react';
import './TokenAdvisory.scss';

interface RedirectModalProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const TokenAdvisory = ({ visible, onOk, onCancel }: RedirectModalProps) => {
  return (
    <Modal
      visible={visible}
      title={null}
      closable={false}
      footer={null}
      autoFocus={false}
      maskClosable={false}
      className="TokenAdvisory-model"
    >
      <div className='title'>Redirecting to Token Advisory Site</div>
      <p style={{
        fontSize: '16px',
        marginBottom: '16px',
        lineHeight: '1.5',
        color: '#ffffff',
      }}>
   Orichal’s Token Advisory services are offered under a separate entity, fully independent from our proprietary investment arm.
      </p>
      
      <p style={{
        fontSize: '16px',
        marginBottom: '16px',
        lineHeight: '1.5',
        color: '#ffffff',
      }}>
        You’re about to be redirected to our dedicated Token Advisory website:{' '}
        <a 
          href="https://opm.trading" 
          style={{
            color: '#4ACBD3',
            textDecoration: 'none',
            fontWeight: 500,
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          opm.trading
        </a>
      </p>

      <p style={{
        fontSize: '16px',
        marginBottom: '16px',
        lineHeight: '1.5',
        color: '#ffffff',
      }}>
     Click Continue to proceed with redirection.
      </p>

      <div className='btn'>
        <Button  type='secondary' onClick={onCancel} >Cancel</Button>
        <Button type='primary' onClick={onOk} >Continue</Button>
      </div>
    </Modal>
  );
};

export default TokenAdvisory;