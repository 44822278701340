import { Link } from "react-router-dom";
import "./Home.scss";
import { useEffect, useState } from "react";
import { Modal, ModalProps } from "../Orichal";

type Props = {
  setModal: React.Dispatch<React.SetStateAction<ModalProps>>;
};

const Home = (props: Props) => {
  const { setModal } = props;

  const [carouselPosition, setCarouselPosition] = useState<number>(0);
  const [mobileCarouselPosition, setMobileCarouselPosition] = useState<number>(0);
  const [carouselTouched, setCarouselTouched] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    !carouselTouched &&
      setTimeout(() => {
        setCarouselPosition(carouselPosition === 4 ? 0 : carouselPosition + 1);
        setMobileCarouselPosition(mobileCarouselPosition === 6 ? 0 : mobileCarouselPosition + 1);
      }, 3000);
  }, [carouselPosition, mobileCarouselPosition, carouselTouched]);

  return (
    <div className="home-wrapper">
      <div className="hero-wrapper">
        <div className="title-list">
        <h1>
        Investing in the Future of <br />
        Digital Assets with Confidence
        </h1>
        {/* <h1>
          Investing in the Future of
          Digital Assets with Confidence.
        </h1>
        <h1>
        Transcending Liquidity.
        </h1> */}
        </div>
    
        <h2>
          Since our establishment in 2017, Orichal has emerged as the premier boutique firm for 
          <br />
          proprietary trading and
          token
          advisory, driving digital asset innovation at the forefront.
        </h2>
      </div>
      <div className="tag-wrapper">
        <h3>
          Providing bespoke digital assets services for private
          <br />
          clients, institutions, and token projects.
        </h3>
      </div>
      <div className="main-pages">
        <div className="mp investment">
          <h2>Investment</h2>
          <h3>Accelerate your project towards its short and long term goals with a validated tokenomics model.</h3>
          <Link to="/investment">
            <button>EXPLORE</button>
          </Link>
        </div>
        <div className="mp advisory">
          <h2>Token Advisory</h2>
          <h3>Grow your token project with our full-suite market making services.</h3>
          <a href="https://opm.trading" target="_blank" rel="noopener noreferrer">
            <button>EXPLORE</button>
          </a>
        </div>
      </div>
      <h3 className="partners-header">OUR NETWORKS AND CLIENTS</h3>
      <div className="logo-parade-wrapper">
        <div className="logo-parade">
          <div className="logo lylaw" />
          <div className="logo coinbase" />
          <div className="logo abacus" />
          <div className="logo ripple" />
          <div className="logo singularity" />
          <div className="logo carry" />
          <div className="logo zilliqa" />
          <div className="logo nexo" />
          <div className="logo fantom" />
          <div className="logo orbs" />
          <div className="logo zklend" />
          <div className="logo dehorizon" />
          <div className="logo the9" />
          <div className="logo eighthours" />
          <div className="logo allme" />
          <div className="logo treelion" />
          <div className="logo axion" />
          <div className="logo cachet" />
          <div className="logo dcentral" />
          <div className="logo lendingblock" />
          <div className="logo gifto" />
          <div className="logo fuzex" />
          <div className="logo amo" />
          <div className="logo nexmoon" />
          <div className="logo coinseer" />
          <div className="logo hold" />
          <div className="logo magnachain" />
          <div className="logo zeex" />
          <div className="logo pundix" />
          <div className="logo iht" />
          <div className="logo playtable" />
        </div>
      </div>
      {/* <div className="carousel-control">
        {carouselPosition > 0 && (
          <div className="arrowLeft desktopOnly" onClick={() => setCarouselPosition(carouselPosition - 1)}>
            &#x25C0;
          </div>
        )}
        <div
          className={"arrowLeft mobileOnly" + (mobileCarouselPosition === 0 ? " disabled" : "")}
          onClick={() => mobileCarouselPosition > 0 && setMobileCarouselPosition(mobileCarouselPosition - 1)}
        >
          &#x25C0;
        </div>
        <div className="carousel desktopOnly">
          <div className={"logo-slides pos" + carouselPosition}>
            <div className="logo-slide">
              <div className="logo lylaw" />
              <div className="logo coinbase" />
              <div className="logo abacus" />
              <div className="logo ripple" />
              <div className="logo singularity" />
            </div>
            <div className="logo-slide">
              <div className="logo carry" />
              <div className="logo zilliqa" />
              <div className="logo nexo" />
              <div className="logo fantom" />
              <div className="logo orbs" />
            </div>
            <div className="logo-slide">
              <div className="logo zklend" />
              <div className="logo dehorizon" />
              <div className="logo the9" />
              <div className="logo eighthours" />
              <div className="logo allme" />
            </div>
            <div className="logo-slide">
              <div className="logo treelion" />
              <div className="logo axion" />
              <div className="logo cachet" />
              <div className="logo dcentral" />
            </div>
            <div className="logo-slide">
              <div className="logo lendingblock" />
              <div className="logo gifto" />
              <div className="logo fuzex" />
              <div className="logo amo" />
            </div>
            <div className="logo-slide">
              <div className="logo nexmoon" />
              <div className="logo coinseer" />
              <div className="logo hold" />
              <div className="logo magnachain" />
            </div>
            <div className="logo-slide">
              <div className="logo zeex" />
              <div className="logo pundix" />
              <div className="logo iht" />
              <div className="logo playtable" />
            </div>
          </div>
        </div>
        <div className="carousel mobileOnly">
          <div className={"logo-slides pos" + mobileCarouselPosition}>
            <div className="logo-slide">
              <div className="logo lylaw" />
              <div className="logo coinbase" />
              <div className="logo abacus" />
              <div className="logo ripple" />
              <div className="logo singularity" />
            </div>
            <div className="logo-slide">
              <div className="logo carry" />
              <div className="logo zilliqa" />
              <div className="logo nexo" />
              <div className="logo fantom" />
              <div className="logo orbs" />
            </div>
            <div className="logo-slide">
              <div className="logo zklend" />
              <div className="logo dehorizon" />
              <div className="logo the9" />
              <div className="logo eighthours" />
              <div className="logo allme" />
            </div>
            <div className="logo-slide">
              <div className="logo treelion" />
              <div className="logo axion" />
              <div className="logo cachet" />
              <div className="logo dcentral" />
            </div>
            <div className="logo-slide">
              <div className="logo lendingblock" />
              <div className="logo gifto" />
              <div className="logo fuzex" />
              <div className="logo amo" />
            </div>
            <div className="logo-slide">
              <div className="logo nexmoon" />
              <div className="logo coinseer" />
              <div className="logo hold" />
              <div className="logo magnachain" />
            </div>
            <div className="logo-slide">
              <div className="logo zeex" />
              <div className="logo pundix" />
              <div className="logo iht" />
              <div className="logo playtable" />
            </div>
          </div>
        </div>
        {carouselPosition < 4 && (
          <div className="arrowRight desktopOnly" onClick={() => setCarouselPosition(carouselPosition + 1)}>
            &#x25B6;
          </div>
        )}
        <div
          className={"arrowRight mobileOnly" + (mobileCarouselPosition === 6 ? " disabled" : "")}
          onClick={() => mobileCarouselPosition < 6 && setMobileCarouselPosition(mobileCarouselPosition + 1)}
        >
          &#x25B6;
        </div>
      </div>
      <div className="dots desktopOnly">
        {[true, true, true, true, true].map((bool, j) => (
          <div
            key={j}
            className={"dot " + (carouselPosition === j ? "selected" : "")}
            onClick={() => {
              setCarouselPosition(j);
              setCarouselTouched(bool); //this is unnecessary but whatever
            }}
          >
            <div className="inner-dot" />
          </div>
        ))}
      </div>
      <div className="dots mobileOnly">
        {[true, true, true, true, true, true, true].map((bool, j) => (
          <div
            key={j}
            className={"dot " + (mobileCarouselPosition === j ? "selected" : "")}
            onClick={() => {
              setCarouselPosition(j);
              setCarouselTouched(bool); //this is unnecessary but whatever
            }}
          >
            <div className="inner-dot" />
          </div>
        ))}
      </div> */}
      <div className="cards-wrapper">
        <h3>WHY ORICHAL</h3>
        <h2>
          With our longstanding presence in the industry, we bring a wealth of experience and expertise to the table.
        </h2>
        <div className="cards">
          <div className="card">
            <div className="card-img nimbleness" />
            <h3>Boutique Agility</h3>
            <h2>
              At Orichal, our boutique agility sets us apart from traditional asset gatherers. We swiftly construct
              high-conviction portfolios and seize opportunities in the ever-changing crypto market, ensuring investor
              success. Backed by our trusted track record as one of Asia's longest-standing digital asset investors.
            </h2>
          </div>
          <div className="card">
            <div className="card-img experience" />
            <h3>Deep Trading Expertise</h3>
            <h2>
              Experience Orichal's deep trading expertise in the digital asset landscape. With a founding team each
              bringing over a decade of traditional finance experience, our seasoned experts in quant research and
              technology provide unparalleled guidance. Since 2017, our laser-focused commitment to digital assets
              positions us at the forefront, navigating risks and rewards with precision for optimal client results.
            </h2>
          </div>
        </div>
      </div>
      <div className="whiteback dark">
        <h3>OUR JOURNEY</h3>
        <h2>Read about our legacy of success in the digital revolution.</h2>
        <Link to={"/team"}>
          <h2 className="read-more">READ MORE</h2>
        </Link>
      </div>
      <div className="stats-wrapper">
        <h3>PERFORMANCE HISTORY</h3>
        <div className="stats">
          <div className="stat">
            <p>Deployed Investment</p>
            <h1>$2B+</h1>
          </div>
          <div className="divider" />
          <div className="stat">
            <p>CAGR</p>
            <h1>25%+</h1>
          </div>
          <div className="divider" />
          <div className="stat">
            <p>Crypto Industry Experience</p>
            <h1>8YR+</h1>
          </div>
          <div className="divider" />
          <div className="stat">
            <p>Supported Token Projects</p>
            <h1>200+</h1>
          </div>
        </div>
        <div className="map-wrapper">
          <div className="map" />
          <div className="maptext">
            <h1>Working with industry leaders in 22 countries</h1>
            <p>
              We support industry leaders across many verticals with our industry-specific expertise, from institutional
              investors to emerging token projects.
            </p>
          </div>
        </div>
        <button className="speak" onClick={() => setModal({ state: Modal.Contact })}>
          SPEAK WITH US TODAY
        </button>
      </div>
    </div>
  );
};

export default Home;
