import { useEffect, useState } from "react";
import "./Advisory.scss";
import { Modal, ModalProps } from "../Orichal";
import { Link } from "react-router-dom";

type Props = {
  setModal: React.Dispatch<React.SetStateAction<ModalProps>>;
  seenAdvisoryModal: boolean;
};

enum MobileComparison {
  SelfServe = 0,
  FullServe = 1,
}

const arcDots = [
  [
    "",
    "1. Tokenomics",
    "Accelerate your project towards its short and long term goals with a validated tokenomics model.",
  ],
  [
    "shorter",
    "2. Deep Liquidity",
    "Encourage greater investor participation by providing deep liquidity 24/7 within your order books.",
  ],
  [
    "",
    "3. Dynamic Liquidity Strategies",
    "Respond to ever changing market conditions by implementing dynamic liquidity strategies.",
  ],
  [
    "longer",
    "4. Insights",
    "Decide whether to maintain or adjust your approach by using real-time market macro and behavioral data.",
  ],
];

const tableRows = [
  ["", "Self-serve Market Making", "Full-Service Market Making"],
  ["Control", "The project maintains full control through secure self-custody."],
  ["Reporting", "The project receives weekly progress reports from Orichal."],
  [
    "Continuous order book liquidity",
    "The project receives access to Orichal’s standard set of tools and algos to do their token liquidity.",
    "Orichal experts deploy advanced tools and algos to provide token liquidity.",
  ],
  [
    "Liquidity provision ownership",
    "The project decides, calibrates and executes on liquidity strategies on their own as they see fit.",
    "Orichal experts manage and execute precise liquidity strategies dynamically end-to-end on behalf of the project.",
  ],
  [
    "Token growth strategy ownership",
    "The project takes the lead and needs to develop their own token growth strategy.",
    "Orichal takes the lead and develops a tailored token growth strategy for the project.",
  ],
  [
    "Research and data insights",
    "The project needs to conduct their own research to inform their token growth strategy.",
    "Orichal uses in-house expert data-driven insights to inform the project’s token growth strategy.",
  ],
  [
    "Project strategy calibration",
    "The project is responsible for market testing and optimizing their own project strategy.",
    "Orichal will continuously optimize the project strategy through insights from real-time market and behavioural data.",
  ],
];

const reasons = [
  [
    "outstanding",
    "1. Outstanding Experience",
    "We proactively provide ongoing detailed statistics and insights reports so you can assess our effectiveness at any given time. Our operating model does not hold client’s assets so that token issuers also maintain greater control and security.",
  ],
  [
    "expertise",
    "2. Expertise on your side",
    "Our team has a background in traditional trading since 2008 and crypto trading since 2017. Having been on both sides of the table (as a token issuer and advisor) we know how to get the job done at speed and scale.",
  ],
  [
    "bespoke",
    "3. Bespoke Solutions",
    "We know that no two token issuers are alike, which is why we tailor our services to the unique needs and goals of your project.",
  ],
  [
    "transparency",
    "4. Full Transparency",
    "We proactively provide ongoing detailed statistics and insights reports so you can assess our effectiveness at any given time. Our operating model does not hold client’s assets so that token issuers also maintain greater control and security.",
  ],
  [
    "ops",
    "5. Reliable Operations",
    "We operate in all market climates and cycles, offering consistent trading support for both liquid and highly illiquid tokens under all market conditions.",
  ],
  [
    "risk",
    "6. Risk Management",
    "Our operating model ensures that your assets are safe. We only operate market making inventory through a managed account, so that token issuers maintain greater control and security.",
  ],
];

const Advisory = (props: Props) => {
  const { setModal, seenAdvisoryModal } = props;

  const [carouselPosition, setCarouselPosition] = useState<number>(0);
  const [carouselTouched, setCarouselTouched] = useState<boolean>(false);
  const [slide, setSlide] = useState<number>(0);
  const [mobileComparisonToggle, setMobileComparisonToggle] = useState<MobileComparison>(MobileComparison.SelfServe);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    !seenAdvisoryModal &&
      setModal({
        state: Modal.AdvisoryTerms,
      });
  }, [setModal, seenAdvisoryModal]);

  useEffect(() => {
    !carouselTouched && setTimeout(() => setCarouselPosition(carouselPosition === 3 ? 0 : carouselPosition + 1), 3000);
  }, [carouselPosition, carouselTouched]);

  return (
    <div className="advisory-wrapper">
      <div className="blueback hundyvh">
        <h3 className="translucent">
          TOKEN ADVISORY &<br className="mobileOnly" /> LIQUIDITY MANAGEMENT
        </h3>
        <div className="title-list">
        <h1>
        The Time to Invest in Digital Assets Is Now.
        </h1>
        <h1>
          Investing in the Future of
          Digital Assets with Confidence.
        </h1>
        <h1>
        Transcending Liquidity.
        </h1>
        </div>
        <h2>
          Since our establishment in 2017, Orichal has emerged as a leading boutique digital asset market
          <br className="desktopOnly" />
          maker, specializing in providing comprehensive solutions to token issuers at all stages.
        </h2>
      </div>
      {seenAdvisoryModal && (
        <div className="blueback light">
          <h1>It's Beyond Liquidity</h1>
          <h3>
            Four necessary foundations to
            <br className="mobileOnly" /> drive token mass adoption.
          </h3>
          <div className="arc">
            <div className="donuthole" />
            {arcDots.map((ad, i) => (
              <div className={"dot dot" + i} key={"arcdot" + i}>
                <div className={"line " + ad[0]} />
                <div className={"line2 " + ad[0]} />
                <div className={"dot enddot " + ad[0]} />
                <h1 className={ad[0]}>{ad[1]}</h1>
                <p className={ad[0]}>{ad[2]}</p>
              </div>
            ))}
          </div>
          <div className="foundations">
            {arcDots.map((f, i) => (
              <div className="foundation" key={"f" + i}>
                <h1 className={i === 2 ? "dblrow" : ""}>{f[1]}</h1>
                <div className="spine">
                  <div className="line">
                    <div className="dot inner" />
                  </div>
                  <div className="dot outer" />
                </div>
                <p>{f[2]}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="whiteback">
        <h3 className="lg blue mb16">Your one-stop solution for token liquidity management</h3>
        <h2 className="blue">
          Our successful involvement in <b>35+ emerging projects</b> and managing over <b>$3.8 billion USD</b> in traded
          volume affirms our trusted position as a top-rated market maker for early and growth-stage tokens.
        </h2>
        <div style={{ height: 69 }} />
        <h3 style={{ marginBottom: 24 }}>WHY ORICHAL?</h3>
        <h2 className="blue">Here are the top reasons why projects entrust Orichal with their liquidity needs.</h2>
        <div className="reasons">
          {reasons.map((r, i) => (
            <div className={"reason " + ([1, 3, 5].includes(i) ? " rev" : "")} key={"reason" + i}>
              <div className={"graphic " + r[0]} />
              <div className={"text " + r[0]}>
                <h1>{r[1]}</h1>
                <p>{r[2]}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="whiteback dark">
        <h3 className="lg blue mb16">How to Launch with Confidence</h3>
        <h2 className="blue">It just takes a few steps.</h2>
        <div className="confidence">
          <div className="con">
            <div className="lines">
              <div className="bump">
                <div className="dot" />
              </div>
              <div className="line big" />
              <div className="bump">
                <div className="dot" />
              </div>
              <div className="line baby" />
            </div>
            <div className="text">
              <h3>Start Here</h3>
              <p className="fixedheight">
                For projects still in the process of choosing on a market making service provider, we offer a
                stand-alone <b>Launch Package</b>. This is a one-off and commitment-free comprehensive{" "}
                <b>Tokenomics Review</b> where we conduct simulations, stress tests, and consultations.
                <br />
                <br />
                <b>Why is this important?</b>
                <br />
                We know that understanding how your tokenomics model unfolds in the market is critical to setting your
                project up for success. Whether you decide to partner with us or explore other options, we highly
                recommend every project to do this in case you haven’t done so yet.
              </p>
              <h3 className="mtop">Then Decide</h3>
              <p>
                If you choose to proceed with us, the cost of the Launch Package will be deducted from your retainer for
                one of our two <b>Market Making</b> services. Full- Service and Self-Serve are available, depending on
                your needs.
              </p>
            </div>
          </div>
          <div className="illustration">
            {/* <div className="decision launch-package">
              <span>Launch Package</span>
            </div>
            <div className="then-decide arrows">
              <span>&#8595;</span>
              <span>&#8595;</span>
            </div>
            <div className="then-decide">
              <div className="decision mm">
                <span>
                  Self-Serve
                  <br /> Market Making
                </span>
              </div>
              <div className="decision mm fs">
                <span>
                  Full Service
                  <br /> Market Making
                </span>
              </div>
            </div> */}
            <div className="illustration-inner" />
            <span className="faq">
              For more info, please see our <Link to="/faq">FAQ</Link>
            </span>
          </div>
        </div>
      </div>
      <div className="whiteback prune">
        <div className="tag-wrapper">
          <h3>
            Bespoke Market Making Solutions from
            <br />
            Inception to Widespread Adoption
          </h3>
          <h2>Choose a service that suits your projects needs.</h2>
        </div>
        <div className="options">
          <div className="option">
            <a href="#selfserve">
              <div className="optionlink">
                <h2>Option 1</h2>
              </div>
            </a>
            <h3>Self-Serve Market Making</h3>
            <p>Most suitable for projects that:</p>
            <ul>
              <li>Only need to maintain liquidity</li>
              <li>Require light touch support from Orichal</li>
              <li>Prefer a budget-friendly market making option</li>
            </ul>
          </div>
          <div className="option">
            <a href="#fullserve">
              <div className="optionlink">
                <h2>Option 2</h2>
              </div>
            </a>
            <h3>Full Service Market Making</h3>
            <p>Most suitable for projects that:</p>
            <ul>
              <li>Need more than maintaining liquidity</li>
              <li>Require comprehensive support from Orichal</li>
              <li>Prefer everything to be taken off their plate by Orichal</li>
            </ul>
          </div>
        </div>
        <div className="comparison desktop">
          {tableRows.map((tr, i) => (
            <div
              className={"row" + (i === 0 ? " headers" : "") + ([2, 4, 6].includes(i) ? " even" : "")}
              key={"tr" + i}
            >
              <div className={"cell category" + (i === 0 ? " header" : "")}>{tr[0]}</div>
              {tr.length === 3 && (
                <div className={"cell selfserve" + (i === 0 ? " header" : "")}>
                  <span>{tr[1]}</span>
                </div>
              )}
              {tr.length === 3 && (
                <div className={"cell fullserve" + (i === 0 ? " header" : "")}>
                  <span>{tr[2]}</span>
                </div>
              )}
              {tr.length === 2 && (
                <div className="cell fullwidth">
                  <span>{tr[1]}</span>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="toggles">
          <div
            className={"toggle" + (mobileComparisonToggle === MobileComparison.SelfServe ? " selected" : "")}
            onClick={() => setMobileComparisonToggle(MobileComparison.SelfServe)}
          >
            <span>
              Self-Serve
              <br />
              Market Making
            </span>
          </div>
          <div
            className={"toggle" + (mobileComparisonToggle === MobileComparison.FullServe ? " selected" : "")}
            onClick={() => setMobileComparisonToggle(MobileComparison.FullServe)}
          >
            <span>
              Full Service
              <br />
              Market Making
            </span>
          </div>
        </div>
        <div className="comparison mobile">
          {tableRows.slice(1).map((tr, i) => (
            <div className={"row" + ([1, 3, 5].includes(i) ? " even" : "")} key={"mtr" + i}>
              <div className="cell category">{tr[0]}</div>
              <div className="cell fullwidth">
                {tr.length === 2 || mobileComparisonToggle === MobileComparison.SelfServe ? tr[1] : tr[2]}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="blueback fullservice desktop" id="fullserve">
        <h3 className="lg white mb16">Full Service Bespoke Market Making</h3>
        <h2>Let us do all the heavy lifting for you.</h2>
        <div className="slideshow">
          <div
            className={"slide-control back" + (slide === 0 ? " disabled" : "")}
            onClick={() => slide > 0 && setSlide(slide - 1)}
          >
            &#x25C0;
          </div>
          <div className="content">
            <div className="text">
              <div className={"slide slide0" + (slide === 0 ? " in" : "")}>
                <h2>Overview</h2>
                <h3>We capture the way you think about your project.</h3>
                <p>
                  This includes identifying and filling in the gaps (if any) to ensure all the necessary ingredients are
                  present.
                </p>
              </div>
              <div className={"slide slide1" + (slide === 1 ? " in" : "")}>
                <h2>Access</h2>
                <h3>We conduct an in-depth review of your tokenomics model and your comparables.</h3>
                <p> This helps to forecast token circulation dynamics, determine optimal liquidity position.</p>
              </div>
              <div className={"slide slide2" + (slide === 2 ? " in" : "")}>
                <h2>Analyse</h2>
                <h3>We build a custom simulation model to run sensitivity analyses and stress tests.</h3>{" "}
                <p>This gives sufficient foresight to plan ahead and de-risk potential obstacles.</p>
              </div>
              <div className={"slide slide3" + (slide === 3 ? " in" : "")}>
                <h2>Execute</h2>
                <h3>We provide ongoing liquidity and decide whether to maintain or adjust your growth strategies.</h3>
                <p>This allows for evolving market making strategies accordingly.</p>
              </div>
              <div className={"slide slide4" + (slide === 4 ? " in" : "")}>
                <h2>Optimize</h2>
                <h3>We proactively share weekly progress reports on your project's trajectory.</h3>
                <p>This facilitates strategic discussions on the project's direction and outcomes.</p>
              </div>
            </div>
            <div className="graphic">
              {/* <div className="axes">
                <div className="arrow x">&#x25B2;</div>
                <div className="arrow y">&#x25B6;</div>
              </div>
              <div className={"fill serve" + slide}>
                <div className="triangle" />
                <div className={"line serve" + slide}>
                  <div className="dot" />
                </div>
              </div> */}
              <div className={"img token_launch" + (slide === 0 ? " in" : "")} />
              <div className={"img token_sale" + (slide === 1 ? " in" : "")} />
              <div className={"img token_rise" + (slide === 2 ? " in" : "")} />
              <div className={"img token_pump" + (slide === 3 ? " in" : "")} />
              <div className={"img token_profit" + (slide === 4 ? " in" : "")} />
            </div>
          </div>
          <div
            className={"slide-control next" + (slide === 4 ? " disabled" : "")}
            onClick={() => slide < 4 && setSlide(slide + 1)}
          >
            &#x25B6;
          </div>
        </div>
      </div>
      <div className="blueback fullservice mobile">
        <h3 className="lg white mb16">
          Full Service Bespoke
          <br />
          Market Making
        </h3>
        <h2>Let us do all the heavy lifting for you.</h2>
        <div className="slideshow">
          <div
            className={"slide-control back" + (slide === 0 ? " disabled" : "")}
            onClick={() => slide > 0 && setSlide(slide - 1)}
          >
            &#x25C0;
          </div>
          <div className="content">
            <div className="text">
              <div className={"slide slide0" + (slide === 0 ? " in" : "")}>
                <h2>Overview</h2>
                <h3>We capture the way you think about your project.</h3>
                <p>
                  This includes identifying and filling in the gaps (if any) to ensure all the necessary ingredients are
                  present.
                </p>
              </div>
              <div className={"slide slide1" + (slide === 1 ? " in" : "")}>
                <h2>Access</h2>
                <h3>We conduct an in-depth review of your tokenomics model and your comparables.</h3>
                <p> This helps to forecast token circulation dynamics, determine optimal liquidity position.</p>
              </div>
              <div className={"slide slide2" + (slide === 2 ? " in" : "")}>
                <h2>Analyse</h2>
                <h3>We build a custom simulation model to run sensitivity analyses and stress tests.</h3>{" "}
                <p>This gives sufficient foresight to plan ahead and de-risk potential obstacles.</p>
              </div>
              <div className={"slide slide3" + (slide === 3 ? " in" : "")}>
                <h2>Execute</h2>
                <h3>We provide ongoing liquidity and decide whether to maintain or adjust your growth strategies.</h3>
                <p>This allows for evolving market making strategies accordingly.</p>
              </div>
              <div className={"slide slide4" + (slide === 4 ? " in" : "")}>
                <h2>Optimize</h2>
                <h3>We proactively share weekly progress reports on your project's trajectory.</h3>
                <p>This facilitates strategic discussions on the project's direction and outcomes.</p>
              </div>
            </div>
            <div className="graphic">
              <div className={"img token_launch" + (slide === 0 ? " in" : "")} />
              <div className={"img token_sale" + (slide === 1 ? " in" : "")} />
              <div className={"img token_rise" + (slide === 2 ? " in" : "")} />
              <div className={"img token_pump" + (slide === 3 ? " in" : "")} />
              <div className={"img token_profit" + (slide === 4 ? " in" : "")} />
            </div>
          </div>
          <div
            className={"slide-control next" + (slide === 4 ? " disabled" : "")}
            onClick={() => slide < 4 && setSlide(slide + 1)}
          >
            &#x25B6;
          </div>
        </div>
      </div>
      <div className="whiteback" id="selfserve">
        <h3 className="lg blue mb16">Self-Service Market Making</h3>
        <h2 className="blue mb-more">
          <b>Simplify success with seamless liquidity.</b>
        </h2>
        <br />
        <h2 className="blue">
          Our self-serve market-making solution ensures uninterrupted liquidity throughout market cycles, eliminating
          the need
          <br className="desktopOnly" /> for third-party reliance. With guidance from our quantitative trading team, we
          assist you in selecting and implementing
          <br className="desktopOnly" /> the most effective liquidity provision strategies to kickstart your journey.
          <br />
          <br />
          Looking for additional resources to drive token growth? Opt for our full-service market-making solutions,
          where we
          <br className="desktopOnly" /> handle all the heavy lifting for you.
        </h2>
        <div className="cards">
          <div className="card">
            <div className="img flex" />
            <h3 className="blue">Flexible Liquidity Provision</h3>
          </div>
          <div className="card">
            <div className="img algos" />
            <h3 className="blue">Impeccable Algos</h3>
          </div>
          <div className="card">
            <div className="img custody" />
            <h3 className="blue">Secure Self-Custody</h3>
          </div>
          <div className="card">
            <div className="img reports" />
            <h3 className="blue">Weekly Progress Reports</h3>
          </div>
        </div>
        {/* <h3 className="lg blue">Your one-stop solution for token liquidity management</h3>
        <h2 className="blue">
          Our successful involvement in <b>35+ emerging projects</b> and managing over <b>$3.8 billion USD</b> in traded
          volume affirms our trusted position as a top-rated market maker for early and growth-stage tokens.
        </h2>
        <div style={{ height: 69 }} />
        <h3 style={{ marginBottom: 24 }}>WHY ORICHAL?</h3>
        <h2 className="blue">Here are the top reasons why projects entrust Orichal with their liquidity needs.</h2>
        <div style={{ height: 120 }} />
        <div className="reasons">
          {reasons.map((r, i) => (
            <div className={"reason " + ([1, 3, 5].includes(i) ? " rev" : "")} key={"reason" + i}>
              <div className={"graphic " + r[0]} />
              <div className={"text " + r[0]}>
                <h1>{r[1]}</h1>
                <p>{r[2]}</p>
              </div>
            </div>
          ))}
        </div> */}
      </div>
      <div className="whiteback">
        <div className="stats-wrapper">
          <h3>PERFORMANCE HISTORY</h3>
          <div className="stats">
            <div className="stat">
              <p>Crypto Market Making Experience</p>
              <h1>6</h1>
            </div>
            <div className="divider" />
            <div className="stat">
              <p>Serviced Projects</p>
              <h1>40+</h1>
            </div>
            <div className="divider" />
            <div className="stat">
              <p>Cumulative Trading Volume</p>
              <h1>10bn+</h1>
            </div>
            <div className="divider" />
            <div className="stat">
              <p className="addedbreak">
                Successful Tier 1 & 2<br className="mobileOnly" />
                Exchange Listings
              </p>
              <h1>12+</h1>
            </div>
            <div className="divider" />
            <div className="stat">
              <p className="addedbreak">
                Zero counterparty fund
                <br className="mobileOnly" />
                loss incidents
              </p>
              <h1>0</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteback">
        <h3 className="blue">TESTIMONIALS</h3>
        {seenAdvisoryModal ? (
          <div className="carousel-control">
            <div
              className={"arrowLeft" + (carouselPosition === 0 ? " hidden" : "")}
              onClick={() => {
                carouselPosition > 0 && setCarouselPosition(carouselPosition - 1);
                setCarouselTouched(true);
              }}
            >
              &#x25C0;
            </div>
            <div className="carousel">
              <div className={"testimonials pos" + carouselPosition}>
                <div className="testimonial">
                  <div className="logo okx" />
                  <div className="quote">
                    <div className="leftquote" />
                    <div className="quotetext">
                      Orichal's support and expertise truly set them apart as a trusted pioneer in the industry.
                      <br />
                      <br />- Jay, Former CEO
                    </div>
                    <div className="rightquote" />
                  </div>
                </div>
                <div className="testimonial">
                  <div className="logo allbit" />
                  <div className="quote">
                    <div className="leftquote" />
                    <div className="quotetext">
                      Orichal's work is outstanding. After witnessing their experienced and in-depth advice tailored to
                      the projects we have worked with, we are happy to support them in the Korean market.
                    </div>
                    <div className="rightquote" />
                  </div>
                </div>
                <div className="testimonial">
                  <div className="logo allme" />
                  <div className="quote">
                    <div className="leftquote" />
                    <div className="quotetext">
                      As our trusted partner, Orichal's data-driven insights and reliable execution have fueled our
                      early-stage success, setting them apart from other market makers.
                      <br />
                      <br />- Ole
                    </div>
                    <div className="rightquote" />
                  </div>
                </div>
                <div className="testimonial">
                  <div className="logo dna" />
                  <div className="quote">
                    <div className="leftquote" />
                    <div className="quotetext">
                      It is a true privilege to participate in Orichal sponsored blockchain events. The production value
                      and caliber of hand-selected guests are consistently high, as are the depth of the connections and
                      the number of follow-ups to be made. DNA was glad to partner with Orichal as an event co-host at
                      Consensus Singapore, and we look forward to doing it again in the near future.
                    </div>
                    <div className="rightquote" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={"arrowRight" + (carouselPosition === 3 ? " hidden" : "")}
              onClick={() => {
                carouselPosition < 3 && setCarouselPosition(carouselPosition + 1);
                setCarouselTouched(true);
              }}
            >
              &#x25B6;
            </div>
          </div>
        ) : null}
        <div className="dots">
          {[true, true, true, true].map((bool, j) => (
            <div
              key={j}
              className={"dot " + (carouselPosition === j ? "selected" : "")}
              onClick={() => {
                setCarouselPosition(j);
                setCarouselTouched(bool); //this is unnecessary but whatever
              }}
            >
              <div className="inner-dot" />
            </div>
          ))}
        </div>
      </div>
      <div className="blueback end">
        <h3 className="translucent">
          TAKE THE NEXT STEP
          <br className="mobileOnly" />
          WITH ORICHAL
        </h3>
        <h1>
          Ready to Unlock the Full Potential
          <br />
          of Your Crypto Project?
        </h1>
        <h2>Book a call with us today to learn more about how we can help accelerate your business growth.</h2>
        <div className="buttons">
          <button onClick={() => setModal({ state: Modal.Contact })}>EMAIL US</button>
        </div>
      </div>
    </div>
  );
};

export default Advisory;
